<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ item.name }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
    >
      <div class="container">
        <div class="blogPost">
          <img
            :src="$baseUploadURL + item.image"
            alt="Image Blog"
            class="img-responsive"
          />
          <h2>
            <router-link :to="$getLink() + `blog-single/` + item.id">{{
              item.name
            }}</router-link>
          </h2>
          <p>
            {{ item.body }}
          </p>
        </div>

        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <form @submit.prevent="addComment">
                <div class="form-group">
                  <label for="body">{{ $t('comment') }}</label>
                  <textarea
                    class="form-control"
                    id="body"
                    rows="6"
                    v-model="body"
                  ></textarea>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="!body">
                  {{ $t('add') }}
                </button>
              </form>
            </li>
            <li
              class="list-group-item"
              v-for="itm in item.blogcomment"
              :key="itm.id"
            >
              <div class="media">
                <img src="../../assets/user.png" style="width: 100px;" class="mr-3" />
                <div class="media-body">
                    <br>
                    <br>
                  <p>
                    {{ itm.comment }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        name: null,
        image: null,
        body: null,
        created_at: null,
        id: null,
        blogcomment: [],
      },
      body: null,
    };
  },
  methods: {
    addComment() {
      if (this.body) {
        this.$http
          .post(`blog/addComment`, {
            user_id: 1,
            blog_id: this.item.id,
            comment: this.body,
          })
          .then(
            (res) => {
              if (res.data) {
                this.item.blogcomment.push(res.data);

                this.body = null;
              }
            },
            (err) => {
              console.log(err);
            },
          );
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.$http.get(`blog/getById/${id}`).then(
        (res) => {
          if (res.data) {
            this.item = res.data;
          }
        },
        (err) => {
          console.log(err);
        },
      );
    }
  },
};
</script>
